<template>
  <div class="mini-menu">
    <div
      v-for="(item, index) in menuList"
      :key="index"
      :class="current === index && 'item-active'"
      class="mini-item"
      @click="change(index)"
    >
      {{ item.title }}
    </div>

    <div class="logo" @click="goHome">
      <img src="~@/assets/images/logo2.png" class="logo-img" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          title: "PROJECT",
          path: "project",
        },
        {
          title: "AWARD",
          path: "award",
        },
        {
          title: "ABOUT",
          path: "about",
        },
        {
          title: "CONTACT",
          path: "contact",
        },
      ],
      current: 0,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  watch: {
    currentRoute: {
      handler(nval) {
        this.menuList.map((item, index) => {
          if ("/" + item.path === nval) {
            this.current = index;
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    change(index) {
      const path = this.menuList[index].path;
      this.$router.push(path);
      this.$emit("change", index);
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.mini-menu {
  width: 100%;
  height: 100%;
  padding: 0 5%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}
.mini-item {
  /* height: 100%;
  display: flex;
  justify-items: center;
  align-items: center; */
  cursor: pointer;
  position: relative;
  user-select: none;
}
.mini-item:hover::after {
  width: 100%;
  transition: width 0.3s;
}
.mini-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #000;
  width: 0%;
  transition: width 0.3s;
}
.item-active::after {
  width: 100%;
  transition: width 0.3s;
}

.logo {
  height: 50px;
  margin-bottom: 40px;
  cursor: pointer;
}

.logo-img {
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .mini-menu {
    font-size: 12px;
  } 
  .logo {
    height: 35px;
    margin-bottom: 15px;  
  }
}
@media only screen and (max-width: 750px) {
  .logo {
    height: 20px;
    margin-top: 8px;
  }
  .mini-menu {
    font-size: 12px;
  }
}
</style>