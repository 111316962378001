<template>
  <div class="about-wrap">
    <h3 class="title">关于</h3>
    <p class="text" v-html="about.content"></p>
    <p class="text" v-html="about.content_en"></p>
    
    <div class="box"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      about: {}
    };
  },
  async created() {
    const { code, data } = await this.__getarticle({type: 2})
    if(code == 1) {
      this.about = data
      this.$eventBus.$emit('changeBg', data.thumb, 2)
    } 
  }
};
</script>

<style scoped>
.about-wrap {
  background-image: url("~@/assets/images/about-bg.png");
  width: 100%;
  min-height: 100%;
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  /* background-attachment: fixed; */
  padding: 0 10% 0 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  margin-bottom: 30px;
  font-size: 20px;
}
.text {
  width: 100%;
  color: #4f4e4c;
  font-weight: 200;
  font-size: 14px;
  line-height: 28px;
  text-align: justify;
}
.text:last-child {
  margin-bottom: 30px;
}
/* @media only screen and (max-width: 750px) {
  .about-wrap {
    padding: 10%;
  }
} */
</style>