<template>
  <div>
    <div class="project-wrap">
      <div class="item" v-for="(item, index) in list" :key="item.id">
        <div
          v-if="index % 2 !== 0"
          class="item-left"
          @click="seeDetails(index)"
        >
          <h2 class="title line-2">
            <img src="~@/assets/images/block-black.png" class="block-white" />
            {{ item.name }}
          </h2>
          <p class="sub-title line-2">{{ item.name_eng }}</p>
          <span class="item-border"></span>
        </div>
        <img v-lazy="item.thumb" class="item-img" @click="seeDetails(index)" />
        <div
          v-if="index % 2 === 0"
          class="item-right"
          @click="seeDetails(index)"
        >
          <h2 class="title line-2">
            <img src="~@/assets/images/block-black.png" class="block-white" />
            {{ item.name }}
          </h2>
          <p class="sub-title line-2">{{ item.name_eng }}</p>
          <span class="item-border"></span>
        </div>
      </div>
    </div>

    <div class="box"></div>

    <transition name="slide-fade">
      <projectDetails
        v-if="list.length && isShow"
        :show="isShow"
        :list="list"
        :isPhone="isPhone"
        :currentList.sync="current"
        @close="close"
      />
    </transition>
  </div>
</template>

<script>
import projectDetails from "@/components/project-details.vue";
// 后续卡的话可以用这个，但是呢没写完  或者有别的方法也行
// import projectDetails from "@/components/project-details-copy.vue";
export default {
  components: {
    projectDetails,
  },
  data() {
    return {
      list: [],
      current: 0,
      isShow: false,
      // 视窗宽度是否大于750 大于750轮播图为一个一张图片，然后为上下布局
      isPhone: true,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  watch: {
    currentRoute() {
      this.isShow = false;
        this.current = 0;
    },
  },
  created() {
    // 监听窗口变化
    if (document.body.offsetWidth <= 750) {
      this.isPhone = false;
    } else {
      this.isPhone = true;
    }
    this.getProject();
  },
  mounted() {
    // 监听窗口变化
    window.onresize = () => {
      if (document.body.offsetWidth <= 750) {
        this.isPhone = false;
      } else {
        this.isPhone = true;
      }
    };
  },
  methods: {
    seeDetails(index) {
      this.current = index;
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
    async getProject() {
      const { code, data } = await this.__getarticle({ type: 1 });
      if (code == 1) {
        this.list = data;
      }
    },
    // 防抖函数
    debounce(func, wait) {
      let timer;
      return function () {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          func();
        }, wait);
      };
    },
  },
};
</script>

<style scoped>
.item {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.item:first-child {
  margin-top: 10px;
}

.item-img,
.item-left,
.item-right {
  /* width: 380px;
  height: 380px; */
  width: 20vw;
  height: 20vw;
  transition: all 0.3s;
  cursor: pointer;
  object-fit: cover;
}
.item-img:hover,
.item-left:hover,
.item-right:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
}
.item-left,
.item-right {
  border: 1px solid #f2f2f2;
  box-shadow: 0 0 1px 1px #f2f2f2;
  padding: 30px;
  box-sizing: border-box;
}
.item-left {
  text-align: left;
}
.item-right {
  text-align: right;
}
.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
}
.block-white {
  width: 20px;
}
.sub-title {
  color: #989898;
  font-size: 24px;
  font-weight: 100;
  margin: 20px 0;
}
.item-border {
  width: 100%;
  height: 1px;
  background-color: #989898;
  display: block;
}

@media only screen and (max-width: 1024px) {
  .title {
    font-size: 16px;
    line-height: 25px;
  }
  .sub-title {
    font-size: 16px;
    margin: 10px 0;
  }
}

@media only screen and (max-width: 750px) {
  .item-img,
  .item-left,
  .item-right {
    width: 40vw;
    height: 40vw;
  }

  .block-white {
    width: 15px;
  }

  .item-left,
  .item-right {
    padding: 10px;
  }

  .title {
    font-size: 16px;
    line-height: 25px;
  }
  .sub-title {
    font-size: 16px;
    margin: 10px 0;
  }
}
</style>