<template>
  <div class="award-wrap">
    <h3 class="title">奖项</h3>

    <ul class="award-conten">
      <li v-for="(item, index) in list" :key="index" class="item-prize">
        <div class="item-prize-year">{{ item.year }}</div>
        <div class="item-prize-contnet">
          <p class="item-prize-contnet-text1">
            {{ item.name_eng }}
          </p>
          <p class="item-prize-contnet-text2">{{ item.name }}</p>
        </div>
      </li>
    </ul>

    <ul class="award-img">
      <template v-for="(item, index) in awardImgList">
        <li :key="index" class="item-conetne">
          <div class="item-img">
            <img v-lazy="item.thumb" @click="seePreview(index)" />
          </div>
          <p class="item-text">{{ item.year }} {{ item.name_eng }}</p>
        </li>
      </template>
    </ul>

    <div class="box"></div>

    <transition name="fade">
      <picPreview
        v-show="isShow"
        :show.sync="isShow"
        :current="current"
        :pic="preview"
        imgMode="height"
      />
    </transition>
  </div>
</template>

<script>
import picPreview from "@/components/picPreview.vue";
export default {
  components: {
    picPreview,
  },
  data() {
    return {
      list: [],
      current: 0,
      isShow: false,
      preview: [],
    };
  },
  created() {
    this.getBg()
    this.getarticle()
  },
  computed: {
    awardImgList() {
      return this.list.filter(item => {
        if(item.thumb) {
          return item
        }
      })
    }
  },
  methods: {
    async getarticle() {
      const { code, data } = await this.__getarticle({ type: 3 });
      if (code == 1) {
        this.list = data;
      }
    },

    async getBg() {
      const { code, data } = await this.__getonebanner({ position: 3 });
      if (code == 1) {
        this.$eventBus.$emit("changeBg", data[0].url, 1);
      }
    },

    seePreview(index) {
      this.current = index;
      let imgList = []
      this.list.map((item) => {
        if (item.thumb) {
          let obj = { url: item.thumb };
          imgList.push(obj)
        }
      });
      this.preview = imgList
      this.isShow = true;
    },
  },
};
</script>

<style scoped>
.award-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 10%;
}
.title {
  margin: 0;
  font-size: 20px;
}
.award-conten {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}
.item-prize {
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 10px;
}
.item-prize-year {
  font-weight: 300;
  font-size: 16px;
}
.item-prize-contnet {
  margin-left: 20px;
  font-weight: 300;
}
.item-prize-contnet-text1,
.item-prize-contnet-text2 {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-size: 14px;
}
.item-prize-contnet-text2 {
  color: #b7b7b7;
}

.award-img {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.item-conetne {
  width: 160px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.item-img {
  width: 160px;
  height: 160px;
  border: 1px solid #f2f2f2;
  overflow: hidden;
  transition: all 0.3s;
  cursor: zoom-in;
}
.item-img:hover {
  box-shadow: 0 0 5px 5px rgba(122, 122, 122, 0.2);
  transform: scale(1.01);
}
.item-img img {
  width: 100%;
}
.item-text {
  margin-top: 15px;
  font-size: 12px;
  color: #b7b7b7;
}


@media only screen and (max-width: 750px) {
  .item-conetne {
    width: 120px;
  }
  .item-img  {
    width: 120px;
    height: 120px;
  }
}
</style>