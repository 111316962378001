<template>
  <div class="pic-preview">
    <swiper v-if="show" ref="mySwiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in pic" :key="index">
        <div class="swiper-item">
          <img :src="item.url" class="swiper-item-img" />
        </div>
      </swiper-slide>

      <div
        class="swiper-button-prev swiper-button-white"
        slot="pagination"
      ></div>
      <div
        class="swiper-button-next swiper-button-white"
        slot="pagination"
      ></div>
    </swiper>
    <img
      src="~@/assets/images/icon-close.png"
      class="item-close"
      @click="close"
    />
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    pic: {
      type: Array,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Number,
      default: 0,
    },
    imgMode: {
      type: String,
      default: 'width'
    }
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: 0,
        Controller: true,
        lazyLoading: true,
        initialSlide: 0,
        centeredSlides: true, 
      },
    };
  },
  watch: {
    current: {
      handler(nval) {
        this.swiperOption.initialSlide = nval;
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style scoped>
.pic-preview {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
}
.pic-preview >>> .swiper-container {
  width: 90%;
  height: 80%;
  margin: 5% auto;
}

.pic-preview >>> .swiper-slide {
  box-sizing: border-box;
}
.swiper-item {
  width: 92%;
  height: 100%;
  position: relative;
  margin: 0 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-item-img {
  /* width: 100%; */
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.item-close {
  position: fixed;
  top: 8%;
  right: 8%;
  width: 40px;
  z-index: 99999;
  cursor: pointer;
}
</style>