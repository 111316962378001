<template>
  <div class="contact-wrap">
    <div class="map-view">
      <baidu-map
        class="map"
        :center="map.center"
        :zoom="map.zoom"
        @ready="handler"
      >
        <!--缩放-->
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
        <!--点-->
        <bm-marker
          :position="map.center"
          :dragging="map.dragging"
          animation="BMAP_ANIMATION_DROP"
        >
          <!--提示信息-->
          <bm-info-window :show="map.show">
            <p class="address">广州市海珠区工业大道南箕路5号五楼507</p>
          </bm-info-window>
        </bm-marker>
      </baidu-map>
    </div>

    <div class="contact-contnet">
      <h3 class="title">联系</h3>
      <p class="text" v-html="contact.content"></p>
      <p class="text" v-html="contact.content_en"></p>
    </div>

    <div class="box"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contact: {},
      map: {
        center: { lng: 113.27664888252107, lat: 23.080329868180304 },
        zoom: 18,
        show: true,
        dragging: false,
      },
    };
  },
  async created() {
    const { code, data } = await this.__getarticle({ type: 4 });
    if (code == 1) {
      this.contact = data;
      this.$eventBus.$emit("changeBg", data.thumb, 3);
    }
    // this.getGeocoding()
  },
  methods: {
    handler({ map }) {
      // 鼠标缩放
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      // map.addEventListener("click", function (e) {
      //   console.log(e.point.lng, e.point.lat);
      // });
    },
    // getGeocoding() {
    //   jsonp('http://api.map.baidu.com/geocoding/v3/', {
    //     address: '广州市海珠区工业大道南箕路5号五楼',
    //     output: 'json',
    //     ak: '9tGGfFOfj0166Gx8QD2nBDDV8Zk6cmIE'
    //   }).then(({status, result}) => {
    //     if(status === 0){
    //       console.log(result.location.lng);
    //       console.log(result.location.lat);
    //       this.map.center.lng = result.location.lng
    //       this.map.center.lat = result.location.lat
    //       this.isShowMap = true
    //     }
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 400px;
}

.map-view {
  width: 100%;
}

.contact-wrap {
  box-sizing: border-box;
  padding: 0 10%;
}

.contact-contnet {
  margin: 40px 0;
}

.title {
  margin-bottom: 40px;
  font-size: 20px;
}
.text {
  font-size: 14px;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.address {
  font-size: 14px;
}


@media only screen and (max-width: 750px) {
  .map {
    height: 260px;
  }
  .contact-wrap {
    padding: 0;
  }
  .contact-contnet {
    padding: 0 10%;
  }
}
</style>