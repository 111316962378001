<template>
  <div :style="{height: boxHeight + 'px'}" class="project-details-view">
    <div class="project-details">
      <div v-show="isPhone">
        <transition-group name="fade2" mode="out-in">
          <template v-for="(item, index) in list" mode="out-in">
            <div
              v-show="currentList === index"
              :key="item.id"
              class="content-left"
            >
              <img :src="item.thumb" class="right-bg" />

              <swiper ref="mySwiper" :options="swiperOption">
                <swiper-slide
                  v-for="(item, indey) in item.swiperBanner"
                  :key="indey"
                >
                  <div class="swiper-item">
                    <img
                      v-for="(child, indez) in item"
                      :key="indez"
                      v-lazy="child.url"
                      class="swiper-item-img"
                      @click="preview(indey, indez)"
                    />
                  </div>
                </swiper-slide>
                <div
                  class="swiper-button-prev swiper-button-white"
                  slot="pagination"
                ></div>
                <div
                  class="swiper-button-next swiper-button-white"
                  slot="pagination"
                ></div>
                <!-- <div class="swiper-button-next swiper-button-black"></div> -->
                <!-- 黑色 -->
              </swiper>
            </div>
          </template>
        </transition-group>
      </div>

      <div class="content-right">
        <div class="mini-menu">
          <miniMenu @change="changeRoute" />
        </div>
        <div class="content-main-box" ref="contentMainBox">
          <transition-group name="fade2" mode="out-in">
            <template v-for="(item, index) in list">
              <div
                v-show="currentList === index"
                :key="item.id"
                class="content-main"
              >
                <div class="swiper-phone">
                  <swiper v-if="!isPhone" :options="swiperOption">
                    <swiper-slide
                      v-for="(child, indey) in item.banner"
                      :key="indey"
                    >
                      <div class="swiper-phone-item">
                        <img
                          v-lazy="child.url"
                          class="swiper-phone-item-img"
                        />
                      </div>
                    </swiper-slide>
                    <div
                      class="swiper-button-prev swiper-button-white"
                      slot="pagination"
                    ></div>
                    <div
                      class="swiper-button-next swiper-button-white"
                      slot="pagination"
                    ></div>
                  </swiper>
                </div>

                <div class="content-text">
                  <p class="text" v-html="item.content"></p>
                  <p class="text" v-html="item.content_en"></p>
                </div>
              </div>
            </template>
          </transition-group>
        </div>

        <div class="change-project">
          <div class="change-project-item" @click="prevProject">
            <img
              class="change-project-item-img"
              src="~@/assets/images/prev.png"
            />
            <p class="change-project-item-text">Last</p>
          </div>
          <div v-if="!isPhone" class="icon-close">
            <img src="~@/assets/images/icon-close.png" @click="changeRoute" />
          </div>
          <div class="change-project-item" @click="nextProject">
            <p class="change-project-item-text">Next</p>
            <img
              class="change-project-item-img"
              src="~@/assets/images/next.png"
            />
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <picPreview
        v-if="isShow"
        :show.sync="isShow"
        :current="currentPreview"
        :pic="list[currentList].banner"
      />
    </transition>
  </div>
</template>

<script>
import miniMenu from "@/components/miniMenu.vue";
import picPreview from "@/components/picPreview.vue";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {
    miniMenu,
    picPreview,
    swiper,
    swiperSlide,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    currentList: {
      type: Number,
      default: 0,
    },
    isPhone: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // swiper 配置项
      swiperOption: {
        loop: false,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 是否查看大图
      isShow: false,
      // 当前点击的的那张大图
      currentPreview: 0,

      boxHeight: this.$windowConfig().windowHeight
    };
  },
  watch: {
    list: {
      handler(nval) {
        // 如果项目列表不为空那么构造轮播图数据（一个轮播项有9张图片）
        if (nval.length) {
          nval.forEach((element) => {
            element.swiperBanner = [];
            for (let i = 0; i < element.banner.length; i += 9) {
              element.swiperBanner.push(element.banner.slice(i, i + 9));
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // 监听窗口变化
    window.onresize = () => {
      this.boxHeight = this.$windowConfig().windowHeight
    };
  },
  methods: {
    // 查看大图 indey:第几个swiper-item indexz第几张图片
    // 因为一个swiper-item显示9张图片 所以最终结果为 (第几个swiper*9)+点击了第几张图片
    preview(indey, indez) {
      let currentPreview = indey * 9 + indez;
      this.currentPreview = currentPreview;
      this.isShow = true;
    },
    // 切换路由，通知父组件关闭详细
    changeRoute() {
      this.isShow = false;
      this.$emit("close");
    },
    // 手机预览大图(一个swiper-iem显示一张图片)
    // previewPhone(index) {
    //   this.currentPreview = index;
    //   this.isShow = true;
    // },
    // 上一个项目
    prevProject() {
      this.currentPreview = -1;
      // 如果当前激活的项目是第一个 那么再次点击那么上一个项目,那么当前浏览的项目就是为最后一个
      if (this.currentList === 0) {
        this.$emit("update:currentList", this.list.length - 1);
      } else {
        // 正常切换
        let c = this.currentList - 1;
        this.$emit("update:currentList", c);
      }
      // 防止点击下一个项目的时候浏览位置还是上次浏览的位置
      this.$nextTick(() => {
        this.$refs.contentMainBox.scrollTop = 0;
      });
    },
    // 下一个项目
    nextProject() {
      this.currentPreview = -1;
      // 如果当前激活的项目等于项目的长度 那么再次点击下一个项目,那么当前浏览的项目就是为第一个
      if (this.currentList === this.list.length - 1) {
        this.$emit("update:currentList", 0);
      } else {
        // 正常切换
        let c = this.currentList + 1;
        this.$emit("update:currentList", c);
      }
      // 防止点击下一个项目的时候浏览位置还是上次浏览的位置
      this.$nextTick(() => {
        this.$refs.contentMainBox.scrollTop = 0;
      });
    },
  },
};
</script>

<style scoped>
.content-left >>> .swiper-container {
  width: 85%;
  margin: 15vh auto 0;
}

.content-left >>> .swiper-slide {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.swiper-item {
  width: 88%;
  margin: 0 auto;
}

.swiper-item-img {
  width: 10.2vw;
  height: 10.2vw;
  margin: 1vw;
  border: 1px solid #fff;
  cursor: zoom-in;
  object-fit: cover;
  transition: all 0.3s;
}
.swiper-item-img:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
  transform: scale(1.01);
}
.project-details-view {
  width: 100vw;
  /* height: 100vh; */
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}
.project-details {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.content-left {
  width: 50vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
}
.right-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  object-fit: cover;
}
.content-right {
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
}
.mini-menu {
  width: 100%;
  height: 150px;
}
.content-main-box {
  flex: 1;
  display: flex;
  position: relative;
  padding: 0 10%;
  overflow-x: hidden;
  overflow-y: auto;
}
.content-main {
  padding: 0 10%;
  /* padding: 0 10%;
  overflow-x: hidden;
  overflow-y: auto; */
  position: absolute;
  left: 0;
  top: 0;
}
.content-main-box::-webkit-scrollbar {
  display: none;
}

.change-project {
  height: 150px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.change-project-item {
  height: 40px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s;
  display: flex;
  align-items: center;
}
.change-project-item-text {
  font-size: 16px;
  margin: 0 10px;
  user-select: none;
}
.change-project-item:hover {
  opacity: 1;
}
.change-project-item-img {
  width: 40px;
  height: 40px;
}
.icon-close {
  width: 40px;
  height: 40px;
}
.icon-close img {
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.swiper-phone {
  width: 100vw;
}
.swiper-phone-item {
  width: 100vw;
  height: 70vw;
  overflow: hidden;
}
.swiper-phone-item-img {
  width: 100%;
  height: 70vw;
  margin: 0;
  padding: 0;
  object-fit: cover;
}

.text {
  margin: 35px 0;
  width: 40%;
  font-size: 14px;
  line-height: 28px;
}

@media only screen and (max-width: 750px) {
  .content-left {
    display: none;
  }
  .content-right {
    width: 100vw;
  }

  .content-main {
    padding: 0;
  }
  .change-project {
    height: 80px;
    box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.2);
  }
  .change-project-item-text {
    font-size: 12px;
  }
  .change-project-item-img{
    width: 25px;
    height: 25px;
  }

  .content-text {
    padding: 0 10vw;
  }
  .text {
    margin: 35px 0;
    /* line-height: 30px; */
    width: 80vw;
  }
  .mini-menu {
    height: 80px;
  }
}
</style>