<template>
  <div class="layout-wrap" ref="layoutWrap">
    <transition name="fade">
      <div v-show="isShow" class="menu">
        <transition-group name="fade-banner" mode="out-in">
          <template v-for="(item, index) in banner">
            <img
              v-show="!isPhone && bannerIndex === index"
              :src="item.url"
              class="home-banner-img"
              :key="index"
            />
          </template>
        </transition-group>
        <img v-show="isPhone" :src="phoneBanner.url" class="home-banner-img" />

        <div class="logo-wrap">
          <img src="~@/assets/images/logo.png" class="logo-img" />
        </div>

        <div class="filing-info">粤ICP备2021120235号</div>
      </div>
    </transition>

    <!-- <div
      v-for="(item, index) in menuList"
      :key="index"
      :style="isShow ? item.style2 : item.style1"
      :class="!isPhone && index === current && 'menu-item-active'"
      class="menu-item"
      @click="changeCurrent(index)"
    >
      <div class="item-content">
        <div class="item-left">
          {{ item.initials }}
        </div>
        <div class="item-right">
          <div class="item-right-title">
            <span v-for="(t, indey) in item.title" :key="indey">
              {{ t }}
            </span>
          </div>
          <div class="item-right-english">
            <span v-for="(e, indez) in item.english" :key="indez">{{ e }}</span>
          </div>
        </div>
      </div>
    </div> -->
    <div
      v-for="(item, index) in menuList"
      :key="index"
      :style="isShow ? item.style2 : item.style1"
      class="menu-item-img"
      @click="changeCurrent(index)"
    >
      <img
        :src="isPhone ? item.img1 : index === current ? item.img1 : item.img2"
        class="menu-item-img-contnet"
      />
    </div>

    <!-- 大屏 -->
    <div v-show="!isShow && !isPhone">
      <transition name="fade">
        <div v-show="!isShow" class="content-wrap">
          <div class="content-right">
            <div class="content-right-bg" ref="contentBg">
              <transition-group name="fade" mode="out-in">
                <template v-for="(item, index) in menuList">
                  <img
                    v-show="index === current"
                    :src="item.bg"
                    :key="index"
                    class="item-bg"
                  />
                </template>
              </transition-group>

              <img
                src="~@/assets/images/block-white.png"
                class="block-white"
                @click="goHome"
              />
            </div>
          </div>
          <div class="content-left">
            <div class="mini-menu">
              <miniMenu />
            </div>
            <div class="content-main">
              <transition name="slide-fade" mode="out-in">
                <keep-alive>
                  <router-view></router-view>
                </keep-alive>
              </transition>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- 小屏 -->
    <transition name="fade">
      <div v-show="!isShow && isPhone">
        <div class="content-wrap">
          <transition-group name="fade" mode="out-in">
            <div key="div1" v-show="isPhoneMenuAvtive" class="phone-right">
              <div class="content-right-bg" ref="contentBg">
                <transition-group name="fade" mode="out-in">
                  <template v-for="(item, index) in menuList">
                    <img
                      v-show="index === current"
                      :src="item.bg"
                      :key="index"
                      class="item-bg"
                    />
                  </template>
                </transition-group>

                <img
                  src="~@/assets/images/block-white.png"
                  class="block-white"
                  @click="goHome"
                />
              </div>
            </div>
            <div
              key="div2"
              v-show="!isPhoneMenuAvtive"
              :style="{
                filter: isPhoneMenuAvtive ? 'brightness(0)' : 'brightness(1)',
              }"
              class="phone-left"
            >
              <transition name="slide-fade" mode="out-in">
                <div class="mini-menu" v-show="!isPhoneMenuAvtive && !isShow">
                  <miniMenu />
                </div>
              </transition>
              <div class="content-main">
                <transition name="slide-fade" mode="out-in">
                  <keep-alive>
                    <router-view></router-view>
                  </keep-alive>
                </transition>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import miniMenu from "@/components/miniMenu.vue";
export default {
  components: {
    miniMenu,
  },
  data() {
    return {
      // 显示首页还是子路由
      isShow: true,
      // 菜单（路由）
      menuList: [
        {
          title: ["项", "目"],
          subTitle: "PROJECT",
          initials: "P",
          english: ["R", "O", "J", "E", "C", "T"],
          style1: {
            top: "25%",
            left: "10%",
          },
          style2: {
            top: "5%",
            left: "5%",
          },
          path: "project",
          bg: "",
          img1: require("@/assets/images/menu-01.png"),
          img2: require("@/assets/images/menu-01-activation.png"),
        },
        {
          title: ["奖", "项"],
          subTitle: "AWARD",
          initials: "A",
          english: ["W", "A", "R", "D"],
          style1: {
            bottom: "25%",
            left: "10%",
          },
          style2: {
            bottom: "5%",
            left: "5%",
          },
          path: "award",
          bg: require("@/assets/images/menu-bg-02.png"),
          img1: require("@/assets/images/menu-03.png"),
          img2: require("@/assets/images/menu-03-activation.png"),
        },
        {
          title: ["关", "于"],
          subTitle: "ABOUT",
          initials: "A",
          english: ["B", "O", "U", "T"],
          style1: {
            top: "25%",
            right: "60%",
          },
          style2: {
            top: "5%",
            right: "5%",
          },
          path: "about",
          bg: require("@/assets/images/menu-bg-03.png"),
          img1: require("@/assets/images/menu-02.png"),
          img2: require("@/assets/images/menu-02-activation.png"),
        },
        {
          title: ["联", "系"],
          subTitle: "CONTACT",
          initials: "C",
          english: ["O", "N", "T", "A", "C", "T"],
          style1: {
            bottom: "25%",
            right: "60%",
          },
          style2: {
            bottom: "5%",
            right: "5%",
          },
          path: "contact",
          bg: require("@/assets/images/menu-bg-04.png"),
          img1: require("@/assets/images/menu-04.png"),
          img2: require("@/assets/images/menu-04-activation.png"),
        },
      ],
      // 当前激活的路由
      current: -1,
      // 首页背景
      banner: [],
      bannerIndex: 0,
      phoneBanner: {},
      timer: null,
      // 是否是手机
      isPhone: false,
      // 手机菜单激活
      isPhoneMenuAvtive: false,

      boxHeight: this.$windowConfig().windowHeight,
    };
  },
  computed: {
    // 当前路由
    currentRoute() {
      return this.$route;
    },
  },
  watch: {
    // 监听当前路由，如果是首页那么展开菜单栏和取消子菜单栏的激活状态
    currentRoute: {
      handler(nval) {
        if (nval.path === "/" || nval.path === "/home") {
          this.current = -1;
          this.isShow = true;
          if (this.isPhone) {
            this.homeAnimation();
          }
        } else {
          this.menuList.map((item, index) => {
            if ("/" + item.path === nval.path) {
              this.current = index;
            }
          });
          this.isShow = false;
          this.isPhoneMenuAvtive = false;
        }
      },
      immediate: true,
    },
    isShow: {
      handler(navl) {
        if (this.isPhone) {
          clearInterval(this.timer);
        } else {
          if (!navl) {
            clearInterval(this.timer);
          } else {
            this.timer = setInterval(() => {
              this.changeBanner();
            }, 3000);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    // 监听窗口变化
    if (document.body.offsetWidth <= 750) {
      this.phoneCalculateMargins();
      this.isPhone = true;
      this.homeAnimation();
    } else {
      this.pcCalculateMargins();
      this.isPhone = false;
    }

    // 获取背景图片
    this.getBanner1();
    this.getBanner2();
    this.getBanner3();

    // 事件总线--接受 其他路由的背景图片
    this.$eventBus.$on("changeBg", (bg, i) => {
      if (bg) {
        this.menuList[i].bg = bg;
      }
    });
  },
  mounted() {
    // 监听窗口变化
    window.onresize = () => {
      this.boxHeight = this.$windowConfig().windowHeight;
      if (document.body.offsetWidth <= 750) {
        this.phoneCalculateMargins();
        this.isPhone = true;
      } else {
        this.pcCalculateMargins();
        this.isPhone = false;
      }
    };
  },
  methods: {
    // 切换路由
    changeCurrent(index) {
      const path = this.menuList[index].path;
      if (this.isPhone) {
        this.isPhoneMenuAvtive = !this.isPhoneMenuAvtive;
        if (!this.isPhoneMenuAvtive) {
          this.$router.push(`/${path}`);
        }
      } else {
        this.isShow = false;
        this.$router.push(`/${path}`);
      }
    },

    goHome() {
      this.isShow = true;
      this.isPhoneMenuAvtive = false;
      this.$router.push("/");
      if (this.isPhone) {
        this.homeAnimation();
      }
    },
    // 首页背景图片
    async getBanner1() {
      const { code, data } = await this.__getonebanner({ position: 1 });
      if (code == 1) {
        this.banner = data;
      }
    },
    // 项目背景图片
    async getBanner2() {
      const { code, data } = await this.__getonebanner({ position: 2 });
      if (code == 1) {
        this.menuList[0].bg = data[0].url;
      }
    },
    // 移动首页背景图片
    async getBanner3() {
      const { code, data } = await this.__getonebanner({ position: 4 });
      if (code == 1) {
        this.phoneBanner = data[0];
      }
    },
    // 首页过度动画
    homeAnimation() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isPhone) {
            this.current = Math.floor(Math.random() * 4);
            this.isPhoneMenuAvtive = true;
          } else {
            this.$router.push("/project");
          }

          this.isShow = false;
        }, 2000);
      });
    },

    // 根据屏幕计算边距
    phoneCalculateMargins() {
      const { windowWidth, windowHeight } = this.$windowConfig();
      const menuItem = 120 * 2;
      let w = (windowWidth - menuItem) / 2;
      let h = (windowHeight - menuItem) / 2;
      this.menuList[0].style1 = {
        top: h + "px",
        left: w + "px",
      };
      this.menuList[1].style1 = {
        bottom: h + "px",
        left: w + "px",
      };
      this.menuList[2].style1 = {
        top: h + "px",
        right: w + "px",
      };
      this.menuList[3].style1 = {
        bottom: h + "px",
        right: w + "px",
      };
    },
    pcCalculateMargins() {
      const { windowWidth, windowHeight } = this.$windowConfig();
      const menuItem = 180;
      let w = (windowWidth / 4 - menuItem) / 2;
      let w1 = w + w / 2;
      let w2 = 2 * 180 + w * 5 + w / 2;
      let h = windowHeight / 2 - menuItem - w / 2;

      this.menuList[0].style1 = {
        top: h + "px",
        left: w1 + "px",
      };
      this.menuList[1].style1 = {
        bottom: h + "px",
        left: w1 + "px",
      };
      this.menuList[2].style1 = {
        top: h + "px",
        right: w2 + "px",
      };
      this.menuList[3].style1 = {
        bottom: h + "px",
        right: w2 + "px",
      };
    },

    changeBanner() {
      if (this.bannerIndex >= this.banner.length - 1) {
        this.bannerIndex = 0;
      } else {
        this.bannerIndex += 1;
      }
    },
  },
  destroyed() {
    // 卸载事件监听
    this.$eventBus.$off("changeBg");
  },
};
</script>

<style scoped>
.layout-wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-image: url("../../assets/images/menu-bg.png"); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.menu::before {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.home-banner-img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* z-index:-1; */
}
.logo-wrap {
  width: 15vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 99;
}
.logo-img {
  width: 100%;
  animation: logo-img-ani 1.8s linear 1;
}
.filing-info {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  color: #a1a1a1;
  font-size: 14px;
}
@keyframes logo-img-ani {
  0% {
    opacity: 0;
    transform: translateX(-70%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.content-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
}
.content-right {
  flex: 1;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.phone-right {
  flex: 1;
  width: 100vw;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  left: 0px;
  top: 0px;
}
.content-right-bg {
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column; */
  overflow: hidden;
  position: relative;
}
.content-right-bg::after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  /* z-index:-1; */
}
.item-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.block-white {
  width: 20px;
  z-index: 99999;
  position: absolute;
  top: 5%;
  left: 8%;
  cursor: pointer;
}
.content-left {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
}
.phone-left {
  width: 100vw;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999999;
}
.mini-menu {
  width: 100%;
  height: 150px;
}
.content-main {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  left: 0;
  top: 0;
}
.content-main::-webkit-scrollbar {
  display: none;
}

.menu-item {
  position: absolute;
  width: 200px;
  height: 200px;
  /* width: 10rem;
  height: 10rem; */
  border: 1px solid rgba(210, 209, 208, 0.5);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  color: rgba(210, 209, 208, 0.5);
  transition: all 0.8s;
  cursor: pointer;
  z-index: 1000;
  user-select: none;
}
.menu-item:hover {
  color: #fff;
  border: 1px solid #fff;
}
.menu-item:nth-child(odd) {
  align-items: flex-start;
  padding-top: 20px;
}
.menu-item:nth-child(even) {
  align-items: flex-end;
  padding-bottom: 20px;
}
.menu-item-active {
  color: #fff;
  border: 1px solid #fff;
}
.menu-item:hover {
  color: #fff;
  border: 1px solid #fff;
}
.item-content {
  width: 100%;
  padding: 0 12%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.item-left {
  height: 100%;
  font-size: 52px;
  font-weight: bold;
  /* margin-right: 2px; */
}
.item-right {
  padding-top: 4px;
  height: 100%;
  /* width: 100px; */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item-right-title {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
}
.item-right-title span {
  margin-left: 40px;
}
.item-right-title span:first-child {
  margin-left: 0;
}
.item-right-english {
  margin-top: 2px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
}

.menu-item-img {
  position: absolute;
  width: 200px;
  height: 200px;
  transition: all 1.8s;
  cursor: pointer;
  z-index: 1000;
  user-select: none;
}
.menu-item-img-contnet {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .menu-item,
  .menu-item-img {
    width: 150px;
    height: 150px;
  }
}
@media only screen and (max-width: 750px) {
  .menu-item-img {
    width: 110px;
    height: 110px;
    transition: all 2.5s;
  }
  .item-content {
    padding: 0 5%;
  }
  .menu-item {
    /* width: 6rem;
    height: 6rem; */
    width: 110px;
    height: 110px;
    transition: all 1.2s;
    border-color: #d2d1d0;
    color: #d2d1d0;
  }
  .item-left {
    width: 20%;
    height: 100%;
    font-size: 29px;
    margin-top: 4px;
    font-weight: bold;
  }
  .item-right-title span {
    margin-left: 22px;
  }
  .item-right {
    width: 60px;
    margin-left: 5px;
  }
  .item-right-title {
    font-size: 10px;
  }
  .item-right-english {
    font-size: 12px;
    margin-top: 1px;
  }
  /* .content-right {
    display: none;
  } */
  .menu-item:nth-child(odd) {
    padding-top: 10px;
  }
  .menu-item:nth-child(even) {
    padding-bottom: 10px;
  }
  .mini-menu {
    height: 80px;
  }
  .menu-item:hover {
    color: #d2d1d0;
    border: 1px solid #d2d1d0;
  }
  .filing-info {
    font-size: 12px;
  }

  .logo-wrap {
    width: 50vw;
  }
}
</style>